// store.js
import { legacy_createStore as createStore } from 'redux'
import { LOGIN_SUCCESS, LOGOUT, SIDEBAR_SET, SIDERBAR_UNFOLDABLE } from './actions/actionTypes.js'

// Función para obtener el token del localStorage
const getAuthTokenFromLocalStorage = () => {
  const authData = localStorage.getItem('authentication')
  return authData ? authData : null
}

// Función para obtener el profile del localStorage
const getProfileFromLocalStorage = () => {
  const authData = localStorage.getItem('profile')
  return authData ? JSON.parse(authData) : null
}

// Funcion para comprobar vecimiento del token
const getExpirationToken = () => {
  const profile = getProfileFromLocalStorage()
  if (profile) {
    return (
      Math.abs(new Date(profile.userLastConnection).getTime() - new Date().getTime()) <=
      24 * 60 * 60 * 1000
    )
  }
  return false
}

const setLogout = () => {
  localStorage.setItem('authentication', null)
  localStorage.setItem('profile', null)
  return false
}

const initialState = {
  sidebarShow: true,
  theme: 'light',
  auth: {
    isAuthenticated: getExpirationToken(),
    token: getAuthTokenFromLocalStorage(),
    profile: getProfileFromLocalStorage(),
  },
}

const changeState = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'set':
      return { ...state, ...payload }
    case SIDEBAR_SET:
      return {
        ...state,
        sidebarShow: payload,
      }
    case SIDERBAR_UNFOLDABLE:
      return {
        ...state,
        sidebarUnfoldable: payload,
      }
    case LOGIN_SUCCESS:
      const { token, profile } = payload
      return {
        ...state,
        auth: {
          ...state.auth,
          isAuthenticated: true,
          token,
          profile,
        },
      }
    case LOGOUT:
      return {
        ...state,
        auth: {
          ...state.auth,
          isAuthenticated: setLogout(),
          token: null,
          profile: null,
        },
      }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
