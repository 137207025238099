import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const TournamentPublic = React.lazy(() => import('./views/tournament/TournamentPublic'))
//TournamentPublic
const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const profile = useSelector((state) => state.auth.profile)

  const isSessionActive = () => {
    return profile != null && isAuthenticated ? true : false
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode('light')
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode('light')
  }, [isColorModeSet, setColorMode, storedTheme])

  return (
    <HashRouter>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route
            exact
            path="/login"
            element={isSessionActive() ? <Navigate to="/" /> : <Login />}
          />
          <Route exact path="/Torneo" element={<TournamentPublic />} />
          <Route exact path="/500" element={<Page500 />} />
          <Route exact path="/404" element={<Page404 />} />
          <Route
            path="*"
            element={isSessionActive() ? <DefaultLayout /> : <Navigate to="/login" />}
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
